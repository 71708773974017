import React, { useEffect, useState } from "react";
import ElectricReportDataTable from "./ElectricReportDataTable";
import styles from "./style/electricReport.module.css";
function ElectricReportRightSectionMain({ selectedMeters }) {
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [dataTableFormData, setDataTableFormData] = useState({});

  useEffect(() => {
    // Get the current date
    const now = new Date();

    // Format it to YYYY-MM-DDTHH:MM
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

    // Set the formatted date-time as the value
    setCurrentDateTime(formattedDateTime);
  }, []);
  const formDataHandler = () => {
    setDataTableFormData({
      startdate: currentDateTime,
      meter_id: selectedMeters,
    });
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-2 form-group">
          <label className="body-text">Date</label>
          <input
            type="datetime-local"
            className="form-control"
            name="startdate"
            value={currentDateTime}
            onChange={(e) => {
              setCurrentDateTime(e.target.value);
            }}
            required
          />
        </div>
        <div className="col-md-2 date-search-submit-btn">
          <button
            type="button"
            className="btn col-md-12"
            style={{ backgroundColor: "#13BFA6", color: "white" }}
            onClick={formDataHandler}
          >
            Submit
          </button>
        </div>
        <div className="col-md-2 date-search-submit-btn">
          <button
            type="button"
            className="btn col-md-12"
            style={{ backgroundColor: "#13BFA6", color: "white" }}
          >
            Export
          </button>
        </div>
      </div>
      {/* <div className={styles.div_container}>
        <div className=" d-flex p-0">
          <span className={styles.flex_text_center}>Date</span>
          <input
            type="date"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #767676",
            }}
          ></input>
        </div>
        <div className=" d-flex p-0">
          <span className={styles.flex_text_center}>Time</span>
          <input
            type="time"
            style={{
              backgroundColor: "transparent",
              border: "1px solid #767676",
            }}
          ></input>
        </div>
        
        
        <div className={styles.flex_text_center}>
          <button className="btn btn-success">Search</button>
        </div>
        <div className={styles.flex_text_center}>
          <button className="btn btn-success">Export</button>
        </div>
        
      </div> */}
      <div>
        <ElectricReportDataTable dataTableFormData={dataTableFormData} />
      </div>
    </div>
  );
}

export default ElectricReportRightSectionMain;
