import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const MonthlyConsumptionBarChart = ({ chartData }) => {
  let barChartData = chartData?.map((data) => {
    return data?.total_active_energy_consumed_kwh;
  });
  let chartLabel = chartData?.map((data) => {
    return data?.day;
  });
  // Sample data for the bar chart
  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: "Daily Energy Consumption",
        data: barChartData,
        backgroundColor: ["#33C0B9"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <Bar data={data} height={104} />
    </div>
  );
};

export default MonthlyConsumptionBarChart;
