import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import useTitle from "../../../../hooks/useTitle";
import "./style/realTimeMonitor.css";
import cityIcon from "./assets/city_icon.png";
import electricityIcon from "./assets/electricity_icon.png";
import energyIcon from "./assets/energy-icon.png";
import carbonIcon from "./assets/carbon_icon.png";
import { MdElectricMeter } from "react-icons/md";
import RightSectionMain from "./RightSectionMain";
import DetailsPage from "./DetailsPage";

function RealTimeMonitor() {
  useTitle("Realtime Monitor");
  const theme = useContext(ThemeContext);
  const [hideMenu, setHideMenu] = useState(false);

  const [meterId, setMeterId] = useState("");

  const detailsClick = () => {
    // alert("details clicked");
    setHideMenu(true);
  };
  const backMenuClick = () => {
    setHideMenu(false);
  };

  return (
    <div className={`container-fluid ${theme}`}>
      <div className="row">
        <div className="col-md-2 left-portion">
          <div className="left-section card-color body-text">
            <div className="in-house-section header-color">
              <div className="in-house-text">In House</div>
              <button className="switch-btn btn btn-info">Switch</button>
            </div>
            <div className="logo-section">
              <img src={cityIcon} alt="cityIcon"></img>
            </div>
            <div className="company-name text-center ">
              Aqualink Bangladesh LTD
            </div>
            <div className="section-separator"></div>
            <div className="status-portion mt-4">
              <div className="row status-section">
                <div className="col-md-6">
                  <div className="text-color-section">
                    <div className="status-name">
                      <div className="section-name col-md-12">
                        <div className="normal-color"></div>
                        <div>Normal</div>
                      </div>
                      <div className="col-md-12 text-center">
                        1 <span style={{ color: "#99BBDB" }}>PCS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-color-section">
                    <div className="status-name">
                      <div className="section-name col-md-12">
                        <div
                          className="normal-color"
                          style={{ background: "#DFB111" }}
                        ></div>
                        <div>Error</div>
                      </div>
                      <div className="col-md-12 text-center">
                        0 <span style={{ color: "#99BBDB" }}>PCS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="text-color-section">
                    <div className="status-name">
                      <div className="section-name col-md-12">
                        <div
                          className="normal-color "
                          style={{ background: "#E83333" }}
                        ></div>
                        <div>Alarm</div>
                      </div>
                      <div className="col-md-12 text-center">
                        0 <span style={{ color: "#99BBDB" }}>PCS</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="text-color-section">
                    <div className="status-name">
                      <div className="section-name col-md-12">
                        <div
                          className="normal-color"
                          style={{ background: "#9D9D9D" }}
                        ></div>
                        <div>Offline</div>
                      </div>
                      <div className="col-md-12 text-center">
                        0 <span style={{ color: "#99BBDB" }}>PCS</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-separator"></div>
            {/* <div className="energy-section mt-3">
              <div className="energy-card">
                <div className="energy-content">
                  <div className="energy-icon">
                    <img src={electricityIcon} alt="electricityIcon" />
                  </div>
                  <div className="energy-text">
                    <div className="col-md-12">
                      Electricity consumption today
                    </div>
                    <div className="col-md-12 value-text">0.04 kWh</div>
                  </div>
                </div>
              </div>
              <div className="energy-card">
                <div className="energy-content">
                  <div className="energy-icon">
                    <img src={energyIcon} alt="energyIcon" />
                  </div>
                  <div className="energy-text">
                    <div className="col-md-12">
                      Comprehensive energy consumption
                    </div>
                    <div className="col-md-12 value-text">0.04 kgce</div>
                  </div>
                </div>
              </div>
              <div className="energy-card">
                <div className="energy-content">
                  <div className="energy-icon">
                    <img src={carbonIcon} alt="carbonIcon" />
                  </div>
                  <div className="energy-text">
                    <div className="col-md-12">Carbon emissions</div>
                    <div className="col-md-12 value-text">0.02 kg</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-md-10 right-portion">
          <div className="right-section card-color body-text">
            <div
              className={`right-section-main ${
                hideMenu ? "d-none" : "d-block"
              }`}
            >
              <RightSectionMain
                onClick={detailsClick}
                setMeterId={setMeterId}
              />
            </div>
            <div
              className={`right-section-details ${
                hideMenu ? "d-block" : "d-none"
              }`}
            >
              <DetailsPage onClick={backMenuClick} meterId={meterId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealTimeMonitor;
