import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({ chartData, chartType }) => {
  let lineChartData = chartData?.map((data) => {
    return data?.total_active_energy_consumed_kwh;
  });
  let chartLabel = chartData?.map((data) => {
    return chartType === "hourly_consumption" ? data?.hour : data?.day;
  });

  const chartLabelTitle =
    chartType === "hourly_consumption"
      ? "Hourly Energy Consumption"
      : "Daily Energy Consumption";

  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: chartLabelTitle,
        data: lineChartData, // Example data values
        fill: false,
        borderColor: "#50C4C1",
        tension: 0.1,
      },
    ],
  };

  return (
    <div>
      <Line data={data} />
    </div>
  );
};

export default LineChart;
