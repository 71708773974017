import React, { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import "./styles/main-dashboard.css";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import DoughnutChart from "./DoughnutChart";

import { AiFillAlert } from "react-icons/ai";
import { GiElectric } from "react-icons/gi";
import { SlSpeedometer } from "react-icons/sl";
import carbonLite from "./assets/carbon-lite.png";
import speedoMeter from "./assets/speedometer-lite.png";
import energyIcon from "./assets/energy-lite.png";
import arrowIcon from "./assets/downArrow.png";
import alarmFirstIcon from "./assets/current-month-lite.png";
import alarmProcessed from "./assets/alarm-processed-lite.png";
import carbonIcon from "./assets/carbon_icon.png";
import electricityIcon from "./assets/electricity_icon.png";

import { useGetAcrelDashboardDataQuery } from "../../../features/acrel/dashboard/acrelDashboardApi";
import MonthlyConsumptionBarChart from "./MonthlyConsumptionBarChart";

function MainDashboard() {
  useTitle("Main Dashboard");
  const theme = useContext(ThemeContext);

  const date = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthName = monthNames[date.getMonth()];
  const currentYear = date.getFullYear();

  const { data, isLoading } = useGetAcrelDashboardDataQuery();
  let doughnutData = {
    label: data?.data?.status_level,
    chartData: data?.data?.status,
  };

  return (
    <div className={`container-fluid ${theme}`}>
      <div className="row">
        <div className="col-md-3 ">
          <div className="row">
            <div className="col-md-12 status">
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">Device status</div>
                </div>
                <div className="card-body">
                  <div className="doughnut-chart">
                    <DoughnutChart chartData={doughnutData} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 alarm">
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">
                    Alarm situation for the current month
                  </div>
                </div>
                <div className="card-body align-items-center">
                  <div className="row mt-3">
                    <div className="col-md-2">
                      {/* <AiFillAlert
                        size={28}
                        color={"#59CEBF"}
                        className=" icon-background"
                      /> */}
                      <img
                        src={alarmFirstIcon}
                        alt="alarmFirstIcon"
                        height="70"
                        width="70"
                      ></img>
                    </div>
                    <div className="col-md-6 justify-content-center align-self-center">
                      <div className="col-md-12 ">Total number in the... </div>
                      <div className="col-md-12 blue-text">0</div>
                    </div>
                    <div className="col-md-3 justify-content-center align-self-center">
                      <div className="col-md-12">Mom</div>
                      <div className="col-md-12 blue-text">--</div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-2">
                      {/* <AiFillAlert
                        size={28}
                        color={"#59CEBF"}
                        className=" icon-background"
                      /> */}
                      <img
                        src={alarmProcessed}
                        alt="alarmProcessed"
                        height="70"
                        width="70"
                      ></img>
                    </div>
                    <div className="col-md-6 justify-content-center align-self-center">
                      <div className="col-md-12 ">
                        Processed in the current month...
                      </div>
                      <div className="col-md-12 blue-text">0</div>
                    </div>
                    <div className="col-md-3 justify-content-center align-self-center">
                      <div className="col-md-12">Mom</div>
                      <div className="col-md-12 blue-text">--</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-12 distribution">
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">
                    Distribution of alarm situations in the past 10 days
                  </div>
                </div>
                <div className="card-body">
                  <LineChart />
                </div>
              </div>
            </div> */}
            <div className="col-md-12 distribution">
              <div className="card-portion card-color body-text">
                <div className="energy-section mt-3">
                  <div className="energy-card">
                    <div className="energy-content">
                      <div className="energy-icon">
                        <img src={electricityIcon} alt="electricityIcon" />
                      </div>
                      <div className="energy-text">
                        <div className="col-md-12">
                          Energy Consumption (Today)
                        </div>
                        <div className="col-md-12 value-text body-text">
                          {data?.data?.today_energy_consumption}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="energy-card">
                    <div className="energy-content">
                      <div
                        className="energy-icon"
                        style={{ transform: "scale(1.4)" }}
                      >
                        <img src={energyIcon} alt="energyIcon" />
                      </div>
                      <div className="energy-text">
                        <div className="col-md-12">
                          Energy Consumption (Yesterday)
                        </div>
                        <div className="col-md-12 value-text body-text">
                          {data?.data?.yesterday_energy_consumption}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="energy-card">
                    <div className="energy-content">
                      <div className="energy-icon">
                        <img src={carbonIcon} alt="carbonIcon" />
                      </div>
                      <div className="energy-text">
                        <div className="col-md-12">Carbon Emission (Today)</div>
                        <div className="col-md-12 value-text body-text">
                          {data?.data?.today_carbon_emission}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12 google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.658920561841!2d90.40631601053192!3d23.795157178550824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c7059a7e45c5%3A0xb8d24bfea1a930!2sAqualink%20Bangladesh%20LTD!5e0!3m2!1sbn!2sbd!4v1710739348734!5m2!1sbn!2sbd"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            {/* <div className="col-md-12" style={{ padding: "5px" }}>
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">Alarm details</div>
                </div>
                <div className="card-body">
                  <table className="alarm-table">
                    <tr>
                      <th>Project name</th>
                      <th>Circuit name</th>
                      <th>Detailed description</th>
                      <th>Alarm level</th>
                      <th>Event type</th>
                      <th>Abnormal time</th>
                    </tr>
                  </table>
                </div>
              </div>
            </div> */}
            <div className="col-md-12" style={{ padding: "5px" }}>
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">
                    {currentMonthName} {currentYear} Energy Consumption
                  </div>
                </div>
                <div className="card-body">
                  {/* <LineChart
                    height="100px"
                    chartType="daily_consumption"
                    chartData={data?.data?.daily_consumption}
                  /> */}
                  <MonthlyConsumptionBarChart
                    chartData={data?.data?.daily_consumption}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 device-status">
          {" "}
          <div className="row">
            <div className="col-md-12 status">
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">Energy statistics today</div>
                </div>
                <div className="card-body row">
                  <div className="col-md-12 mt-2">
                    <div className="row section-size">
                      <div className="col-md-2">
                        <img src={energyIcon} alt="speedoMeter"></img>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="col-md-12 ">Electricity (kWh)</div>
                            <div className="col-md-12 blue-text body-text">
                              {data?.data?.today_energy_consumption}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="">Yoy</div>
                            <div className="blue-text body-text">
                              {" "}
                              {data?.data?.yearly_energy_consumption}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="">Mom</div>
                        <div
                          className="blue-text"
                          style={{ display: "flex", gap: "1rem" }}
                        >
                          <div className="body-text">
                            {data?.data?.monthly_energy_consumption}
                          </div>
                          <img src={arrowIcon} alt="arrowIcon" width={15}></img>
                        </div>
                      </div>
                    </div>
                    <div className="divider-line" />
                  </div>
                  {/* <div className="col-md-12 mt-2">
                    <div className="row section-size">
                      <div className="col-md-2">
                        <img src={speedoMeter} alt="speedoMeter"></img>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="col-md-12 ">Comprehensive...</div>
                            <div className="col-md-12 blue-text">0.01</div>
                          </div>
                          <div className="col-md-2">
                            <div className="">Yoy</div>
                            <div className="blue-text">--</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="">Mom</div>
                        <div
                          className="blue-text"
                          style={{ display: "flex", gap: "1rem" }}
                        >
                          <div>-65.4% </div>
                          <img src={arrowIcon} alt="arrowIcon" width={15}></img>
                        </div>
                      </div>
                    </div>
                    <div className="divider-line" />
                  </div> */}
                  <div className="col-md-12 mt-2">
                    <div className="row">
                      <div className="col-md-2">
                        <img src={carbonLite} alt="carbonLite"></img>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-8">
                            <div
                              className="col-md-12 "
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Carbon Emmision
                            </div>
                            <div className="col-md-12 blue-text body-text">
                              {data?.data?.today_carbon_emission}
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="">Yoy</div>
                            <div className="blue-text body-text">
                              {data?.data?.yearly_carbon_emission}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="">Mom</div>
                        <div
                          className="blue-text"
                          style={{ display: "flex", gap: "1rem" }}
                        >
                          <div className="body-text">
                            {data?.data?.monthly_carbon_emission}
                          </div>
                          <img src={arrowIcon} alt="arrowIcon" width={15}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 alarm">
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">
                    Today's energy consumption trend
                  </div>
                </div>
                <div className="card-body">
                  <LineChart
                    chartType="hourly_consumption"
                    chartData={data?.data?.hourly_consumption}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 distribution">
              <div className="card-portion card-color body-text">
                <div className="title-portion">
                  <div className="title-color"></div>
                  <div className="title-text">Carbon emissions today</div>
                </div>
                <div className="card-body">
                  <BarChart chartData={data?.data?.hourly_carbon_emission} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainDashboard;
