import React, { useEffect, useState } from "react";
import { useElectricReportDataMutation } from "../../../../features/acrel/realtimeDashboard/acrelElectricReportApi";

function ElectricReportDataTable({ dataTableFormData }) {
  const [formData, setFormData] = useState(dataTableFormData);
  const [tableData, setTableData] = useState();

  const [electricReportData] = useElectricReportDataMutation();
  useEffect(() => {
    setFormData(dataTableFormData);
  }, [dataTableFormData]);

  useEffect(() => {
    electricReportData(formData)
      .unwrap()
      .then((payload) => setTableData(payload))
      .catch((error) => console.log(error?.status));
  }, [formData]);
  let rows;
  let headers;

  if (tableData) {
    rows = [...tableData];

    headers = rows.length > 0 ? Object.keys(rows[0]) : [];
  }

  return (
    <div style={{ paddingTop: "10px", overflow: "scroll" }}>
      <table style={{ width: "100%" }}>
        <tr>
          {headers?.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
        {rows?.map((row, index) => (
          <tr key={index}>
            {headers.map((header, i) => (
              <td key={i}>{row[header]}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
}

export default ElectricReportDataTable;
