import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const BarChart = ({ chartData }) => {
  let barChartData = chartData?.map((data) => {
    return data?.yearly_carbon_emission_kg;
  });
  let chartLabel = chartData?.map((data) => {
    return data?.hour;
  });
  // Sample data for the bar chart
  const data = {
    labels: chartLabel,
    datasets: [
      {
        label: "Hourly Carbon Emission",
        data: barChartData,
        backgroundColor: [
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
          "#33C0B9",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <Bar data={data} height={205} />
    </div>
  );
};

export default BarChart;
