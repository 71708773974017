import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ElectricParameterDataTable from "./ElectricParameterDataTable";
import styles from "./style/electricParameter.module.css";

function ElectricParameterRightSectionMain({ selectedId }) {
  const [show, setShow] = useState(false);

  const [options, setOptions] = useState([
    { id: 1, name: "Phase Voltage", value: "1", selected: true },
    { id: 2, name: "Line Voltage", value: "2", selected: true },
    { id: 3, name: "Phase Current", value: "3", selected: true },
    { id: 4, name: "Active Power", value: "4", selected: true },
    { id: 5, name: "Reactive Power", value: "5", selected: true },
    { id: 6, name: "Inspecting Power", value: "6", selected: true },
    { id: 7, name: "Power Factor", value: "7", selected: true },
    { id: 8, name: "Active Energy", value: "8", selected: true },
    { id: 9, name: "Reactive Energy", value: "9", selected: true },
  ]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleSelectAll = (event) => {
    const updatedOptions = options.map((option) => ({
      ...option,
      selected: event.target.checked,
    }));
    setOptions(updatedOptions);
  };

  const handleOptionChange = (id) => {
    const updatedOptions = options.map((option) =>
      option.id === id ? { ...option, selected: !option.selected } : option
    );
    setOptions(updatedOptions);
  };

  const selectedOptions = options
    .filter((option) => option.selected)
    .map((option) => option.name);
  const selectedOptionsValue = options
    .filter((option) => option.selected)
    .map((option) => option.value);

  const [currentDateTime, setCurrentDateTime] = useState("");

  useEffect(() => {
    // Get the current date
    const now = new Date();

    // Format it to YYYY-MM-DDTHH:MM
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

    // Set the formatted date-time as the value
    setCurrentDateTime(formattedDateTime);
    setFormData((previousValue) => ({
      ...previousValue,
      startdate: formattedDateTime,
    }));
  }, []);

  const [formData, setFormData] = useState({
    startdate: currentDateTime,
    meter_id: selectedId || "",
    type: selectedOptionsValue || [],
    interval: "5",
  });

  const dataChangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((previousValue) => ({
      ...previousValue,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData((previousValue) => ({
      ...previousValue,
      type: selectedOptionsValue,
    }));
  }, [options]);
  useEffect(() => {
    setFormData((previousValue) => ({
      ...previousValue,
      meter_id: selectedId,
    }));
  }, [selectedId]);

  // console.log(formData);

  return (
    <div>
      <div className="row">
        <div className="col-md-2 form-group">
          <label className="body-text">Circuit Name</label>
          <select
            className="form-select"
            aria-label="Default select example"
            name="meter_id"
            value={formData.meter_id}
            onChange={dataChangeHandler}
          >
            <option value={selectedId || ""} selected>
              {selectedId || ""}
            </option>
          </select>
        </div>
        <div className="form-group col-md-2">
          <label className="body-text">Date</label>
          <input
            type="datetime-local"
            className="form-control"
            name="startdate"
            value={formData.startdate}
            onChange={(e) => {
              setCurrentDateTime(e.target.value);
              dataChangeHandler(e);
            }}
            required
          />
        </div>
        <div className="col-md-2 form-group">
          <label className="body-text">Param Type</label>
          <Form.Control
            type="text"
            readOnly
            value={
              selectedOptions.length > 0
                ? selectedOptions.join(", ")
                : "Select params..."
            }
            onClick={handleShow}
            style={{ cursor: "pointer" }}
          />

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Select Params</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Check
                  type="checkbox"
                  label="Select All"
                  onChange={handleSelectAll}
                  checked={options.every((option) => option.selected)}
                />
                {options.map((option) => (
                  <Form.Check
                    key={option.id}
                    type="checkbox"
                    label={option.name}
                    checked={option.selected}
                    onChange={() => handleOptionChange(option.id)}
                  />
                ))}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="col-md-2 form-group">
          <label className="body-text">Time Interval</label>
          <select
            className="form-select"
            name="interval"
            value={formData.interval}
            onChange={dataChangeHandler}
          >
            <option value="5">five minutes</option>
            <option value="15">fifteen minutes</option>
            <option value="30">half an hour</option>
            <option value="60">one hour</option>
          </select>
        </div>
        {/* <div className="col-md-2 date-search-submit-btn">
          <button
            type="button"
            className="btn col-md-12"
            style={{ backgroundColor: "#13BFA6", color: "white" }}
          >
            Submit
          </button>
        </div> */}
        <div className="col-md-2 date-search-submit-btn">
          <button
            type="button"
            className="btn col-md-12"
            style={{ backgroundColor: "#13BFA6", color: "white" }}
          >
            Export
          </button>
        </div>
      </div>

      <div style={{ zIndex: "-1" }}>
        <ElectricParameterDataTable tableFormData={formData} />
      </div>
    </div>
  );
}

export default ElectricParameterRightSectionMain;
