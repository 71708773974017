import React, { useEffect, useRef } from "react";
import "./drawer.css";
import { useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CgDanger } from "react-icons/cg";
import { RxCross1 } from "react-icons/rx";
import { useGetLatestNotificationQuery } from "../../../features/notification/notificationApi";
import Button from "react-bootstrap/Button";
import { useNotificationActionMutation } from "../../../features/notification/notificationApi";

function Drawer() {
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGetLatestNotificationQuery();
  const [notificationAction] = useNotificationActionMutation();
  let allNotifications = [];
  if (!isLoading) {
    allNotifications = data?.data || [];
    allNotifications = allNotifications.map((latestNotification) => {
      const data = new Date(latestNotification.updated_at);
      const formattedDate = data.toLocaleString();

      return {
        ...latestNotification,
        updated_at: formattedDate,
      };
    });
  }
  const actionHandler = (id, actionType) => {
    // alert(id);
    notificationAction({
      id: id,
      status: actionType,
    });
  };
  return (
    <div>
      <div className="theme-text " onClick={() => setOpen(true)}>
        <IoMdNotificationsOutline size="25px" />
        <div className="icon-tooltip">{allNotifications.length}</div>
      </div>
      <div
        className={`${
          open
            ? "drawer-open theme-background"
            : "drawer-close theme-background"
        }`}
      >
        <div className="header-section">
          <div
            onClick={() => {
              setOpen(!open);
            }}
            className="theme-text close-btn"
          >
            <RxCross1 />
          </div>
          <div className="drawer-title theme-text">Latest Notification</div>
        </div>

        <div className="drawer-body">
          {allNotifications.map((info) => {
            return (
              <div className="drawer-body-element">
                <div className="drawer-element-left-portion">
                  <CgDanger size="55px" color="red" />
                </div>
                <div className="drawer-element-right-portion theme-text">
                  <div className="">
                    {info.room_name}(Level:{info.level})
                  </div>
                  <div className="min-max-date-portion">
                    <div className="min-max">
                      <div>Min: {info.threshold_min}</div>
                      <div>Max: {info.threshold_max}</div>
                    </div>
                    <div className="timestatmp">{info.updated_at}</div>
                  </div>
                  <div className="value">
                    Sensor : {info.sensor_name} &nbsp;&nbsp;&nbsp;&nbsp; Value :{" "}
                    {info.value} {info.unit}
                  </div>
                  <div className="message">{info.message}</div>

                  <div className="buttons">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => actionHandler(info.id, "action")}
                    >
                      Action Taken
                    </Button>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => actionHandler(info.id, "ignore")}
                    >
                      Ignore
                    </Button>{" "}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Drawer;
