import React, { useEffect, useState } from "react";
import { useElectricParameterReportDataMutation } from "../../../../features/acrel/realtimeDashboard/acrelElectricParameterReportApi";

function ElectricParameterDataTable({ tableFormData }) {
  const [formData, setFormData] = useState(tableFormData);
  const [tableData, setTableData] = useState();
  const [electricParameterReportData, { isLoading }] =
    useElectricParameterReportDataMutation();

  useEffect(() => {
    setFormData(tableFormData);
  }, [tableFormData]);
  // console.log(formData);
  useEffect(() => {
    electricParameterReportData(formData)
      .unwrap()
      .then((payload) => setTableData(payload))
      .catch((error) => console.log(error?.status));
  }, [formData]);

  let aggregates;
  let rows;
  let headers;
  let aggregatesKeys;

  if (tableData) {
    aggregates = tableData?.find((item) => item.interval === "Aggregates");

    rows = tableData?.filter((item) => item.interval !== "Aggregates");

    headers = rows.length > 0 ? Object.keys(rows[0]) : [];

    aggregatesKeys = Object.keys(aggregates).filter(
      (key) => key !== "interval"
    );
  }
  const subkeys = ["min", "max", "avg"];

  return (
    <div
      style={{ paddingTop: "10px", overflow: "scroll", overflowX: "scroll" }}
    >
      <table style={{ width: "100%", paddingTop: "10px" }}>
        <tr>
          {headers?.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
        {rows?.map((row, index) => (
          <tr key={index}>
            {headers.map((header, i) => (
              <td key={i}>{row[header]}</td>
            ))}
          </tr>
        ))}
        {subkeys.map((subkey, index) => (
          <tr key={index}>
            <td>{subkey}</td>
            {aggregatesKeys?.map((key, i) => (
              <td key={i}>{aggregates[key][subkey]}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );
}

export default ElectricParameterDataTable;
